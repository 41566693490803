import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTeam, getTeamPhoneSMS, getTeamUsers } from "./get";
import {
  AddTeamUserRequest,
  TeamRequest,
  KnTeamUserRole,
  TeamInviteRequest,
  TeamJoinRequest,
  UpdateTeamUserRequest,
  TeamInvite,
} from "@/services/generated";
import Services from "@/services";
import toast from "@/lib/toast";

interface Team extends TeamRequest {
  id?: string;
  media?: File | null;
}

type State = {
  team?: Team | null;
  draftTeam?: Team | null;
  loading: boolean;
  loadingUpdateUser: boolean;
  error: string | null;
  users: KnTeamUserRole[];
  invites: TeamInvite[];
  joinError: string | null;
  teamPhoneSMS?: string | number | undefined;
  canSave?: boolean;
};

const initialState: State = {
  team: null,
  draftTeam: null,
  loading: false,
  loadingUpdateUser: false,
  error: null,
  users: [],
  invites: [],
  joinError: null,
  canSave: true,
};

export const createTeam = createAsyncThunk(
  "team/createTeam",
  async (draftTeam: Team, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Teams.createTeam(draftTeam);
      if (response.status === 200) {
        toast.success("Team successfully created");
      } else {
        toast.error(response.statusText);
      }

      if (draftTeam?.media) {
        await Services.Teams.uploadLogoPicture(draftTeam?.media);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateTeam = createAsyncThunk("team/updateTeam", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as { settings: any };
  const draftTeam = state.settings.team.draftTeam;
  try {
    await Services.Teams.updateTeam(draftTeam?.id as string, draftTeam);
    toast.success("Team successfully updated");
    if (draftTeam?.media) {
      await Services.Teams.uploadLogoPicture(draftTeam?.media);
      toast.success("Team logo successfully updated");
    }
    return draftTeam;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const addTeamUser = createAsyncThunk(
  "team/addTeamUser",
  async (user: AddTeamUserRequest, { rejectWithValue, dispatch }) => {
    try {
      const userResponse = await Services.TeamUserRoles.addUser(user);
      toast.success("User successfully added");
      return userResponse;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateTeamUser = createAsyncThunk(
  "team/updateTeamUser",
  async (body: UpdateTeamUserRequest, { rejectWithValue, dispatch }) => {
    try {
      const userResponse = await Services.TeamUserRoles.updateUserRole(body);
      toast.success("User successfully updated");
      return userResponse;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const inviteTeamUser = createAsyncThunk(
  "team/inviteTeamUser",
  async (user: TeamInviteRequest, { rejectWithValue, dispatch }) => {
    try {
      await Services.Invite.sendNow(user);
      return user;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const listInvites = createAsyncThunk("team/listInvites", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await Services.Invite.list();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const joinTeam = createAsyncThunk(
  "team/joinTeam",
  async (invitation: TeamJoinRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Invite.join(invitation);
      dispatch(getTeam());
      dispatch(getTeamUsers());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteUserFromTeam = createAsyncThunk(
  "team/deleteUserFromTeam",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await Services.TeamUserRoles.deleteUser(id);
      toast.success("User successfully deleted");
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeam: (state, action) => {
      state.team = action.payload;
    },

    setDraftTeam: (state, { payload }) => {
      state.draftTeam = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeam.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeam.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.team = payload.data;
        }
        state.loading = false;
      })

      .addCase(createTeam.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTeam.fulfilled, (state, { payload }) => {
        if (payload) {
          state.team = payload;
        }
        state.loading = false;
      })
      .addCase(createTeam.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(getTeamUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamUsers.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.users = payload.data;
        }
        state.loading = false;
      })
      .addCase(updateTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTeam.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.team = payload;
      })
      .addCase(inviteTeamUser.fulfilled, (state, { payload }) => {
        if (payload) {
          state.invites = [...state.invites, { email: payload.email, status: "SENT", full_name: payload.fullName }];
        }
      })
      .addCase(listInvites.pending, (state) => {
        state.loading = true;
      })
      .addCase(listInvites.fulfilled, (state, { payload }) => {
        if (payload) {
          state.invites = payload;
        }
        state.loading = false;
      })
      .addCase(updateTeam.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as string; // Handle error
      })

      .addCase(addTeamUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.users = [...state.users, payload.data];
      })

      .addCase(joinTeam.rejected, (state, { payload }) => {
        state.loading = false;
        state.joinError = payload as string;
      })

      .addCase(deleteUserFromTeam.fulfilled, (state, { payload }) => {
        state.users = state.users.filter((user) => user.user_id !== payload);
      })

      .addCase(getTeamPhoneSMS.fulfilled, (state, { payload }) => {
        if (payload.data.phoneNumber) {
          state.teamPhoneSMS = payload.data.phoneNumber;
        }
      })
      .addCase(updateTeamUser.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.users = state.users.map((user) => {
            if (user.user_id === payload.data.user_id) {
              return { ...user, userRole: payload.data.userRole };
            }
            return user;
          });
        }
        state.loadingUpdateUser = false;
      })
      .addCase(updateTeamUser.pending, (state, { payload }) => {
        state.loadingUpdateUser = true;
      })
      .addCase(updateTeamUser.rejected, (state, { payload }) => {
        state.loadingUpdateUser = false;
        state.error = payload as string; // Handle error
      });
  },
});

export const { setTeam, setDraftTeam } = teamSlice.actions;

export default teamSlice.reducer;
